// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-paginated-page-template-js": () => import("/opt/buildhome/repo/src/templates/paginatedPageTemplate.js" /* webpackChunkName: "component---src-templates-paginated-page-template-js" */),
  "component---src-templates-scrapbox-missing-page-js": () => import("/opt/buildhome/repo/src/templates/scrapbox-missing-page.js" /* webpackChunkName: "component---src-templates-scrapbox-missing-page-js" */),
  "component---src-templates-scrapbox-page-js": () => import("/opt/buildhome/repo/src/templates/scrapbox-page.js" /* webpackChunkName: "component---src-templates-scrapbox-page-js" */),
  "component---src-pages-404-js": () => import("/opt/buildhome/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-view-item-js": () => import("/opt/buildhome/repo/src/pages/viewItem.js" /* webpackChunkName: "component---src-pages-view-item-js" */)
}

